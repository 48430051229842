<script setup>
import { Link } from '@inertiajs/vue3';
import { computed } from 'vue';

const props = defineProps({
    flexer_id: String,
    liked_flexer_ids: Array,
});

const isLiked = computed(() => {
    return props.liked_flexer_ids.includes(props.flexer_id);
});
</script>
<template>
    <template v-if="isLiked">
        <Link
            class="absolute z-10 w-6 h-6 left-4 top-4"
            as="button"
            method="delete"
            preserve-state
            preserve-scroll
            :only="['liked_flexer_ids']"
            :href="
                route('location.liked-flexers.destroy', {
                    id: flexer_id,
                })
            "
        >
            <img class="w-6 h-6" src="@/../img/star-yellow.svg" alt="" />
        </Link>
    </template>
    <template v-else>
        <Link
            class="absolute z-10 w-6 h-6 left-4 top-4"
            as="button"
            method="put"
            preserve-state
            preserve-scroll
            :only="['liked_flexer_ids']"
            :href="
                route('location.liked-flexers.update', {
                    id: flexer_id,
                })
            "
        >
            <img class="w-6 h-6" src="@/../img/star-empty-white.svg" alt="" />
        </Link>
    </template>
</template>
